import { NumberSchema, ValidationError, mixed, object } from 'yup'
import { Message } from 'yup/lib/types'
import { AnyObject } from 'yup/lib/object'
import { MixedSchema } from 'yup/lib/mixed'

import { KeyOf } from '../types'

import { msgNumber, msgRequired } from './messages'

export function minMax(this: NumberSchema, min: number, max: number, message: Message<{ min: number; max: number }> = msgNumber) {
  return this.test({
    message,
    name: 'minMax',
    exclusive: true,
    params: { min, max },
    test(value) {
      return value === undefined || value === null || (value! >= min && value! <= max)
    },
  })
}

/**
 * Helper for yup schema definition that allows defining validation for enums and string unions
 * without losing type safety.
 *
 * If you want this schema to allow null/undefined, do the following:
 *
 * oneOfEnum([...objValues(SomeEnum), null]).default(null)
 * oneOfEnum([...objValues(SomeEnum), undefined]).default(undefined)
 *
 * If you want it to allow null/undefined AND be required, do the following:
 *
 * oneOfEnum<SomeEnum | null, SomeEnum | null, SomeEnum>([...objValues(SomeEnum), null]).default(null).required()
 * oneOfEnum<SomeEnum | undefined, SomeEnum | undefined, SomeEnum>([...objValues(SomeEnum), undefined]).default(undefined).required()
 *
 * The order of default and required is important to get correct output types.
 *
 * If you want to create a schema for a numeric enum, use the {@link enumNumberValues} utility to extract just the
 * numeric values from the enum.
 *
 * oneOfEnum(enumNumberValues(SomeNumericEnum)).default(SomeNumericEnum.someKey)
 *
 * TODO: rework as extension for yup schema
 */
export const oneOfEnum = <T, TIn = T | undefined, TOut extends TIn = TIn>(
  enumObject: Record<string, T> | ArrayLike<T>,
  message?: string | Message,
  schema = mixed(),
) => {
  return schema.oneOf<T>(Object.values(enumObject), message) as unknown as MixedSchema<TIn, AnyObject, TOut>
}

/**
 * Defines a schema that accepts any object but asserts as an object of the provided type
 * Useful for putting things in schemas that we don't let the user deeply modify,
 * i.e. an array of objects of a certain type.
 */
export const typeOnlySchema = <T extends Record<string, unknown>>() => object({}) as unknown as MixedSchema<T, AnyObject, T>

export const requiredWhenNotEmpty = <T extends Record<string, unknown>>(
  value: T,
  keys: Array<KeyOf<T>>,
  path: string,
): boolean | ValidationError => {
  const emptyKeys = keys.filter(key => value[key] === null || value[key] === undefined)

  if (emptyKeys.length === keys.length || emptyKeys.length === 0) {
    return true
  }

  return new ValidationError(
    emptyKeys.map(key => new ValidationError(msgRequired(), value[key], `${path}.${key}`)),
  )
}

import { AnySchema, BaseSchema, ValidationError, object } from 'yup'
import { ValidateOptions } from 'yup/lib/types'

import { objEntries, objFromEntries } from '../utils'

export const validate = <T extends BaseSchema>(
  schema: T,
  data: unknown,
  options?: ValidateOptions,
): { success: true; data: T['__outputType'] } | { success: false; error: ValidationError } => {
  try {
    return { success: true, data: schema.validateSync(data, options) }
  }
  catch (error) {
    if (error instanceof ValidationError) {
      return { success: false, error }
    }
    else {
      return { success: false, error: new ValidationError((error as Error).message) }
    }
  }
}

export type ExtendersShape<Shape extends Record<string, AnySchema>> = {
  [K in keyof Shape]?: (schema: Shape[K]) => AnySchema
}

export const extendSchemaShape = <Shape extends Record<string, AnySchema>, Extenders extends ExtendersShape<Shape>>(
  shape: Shape,
  extenders: Extenders,
): Shape => {
  return objFromEntries(
    objEntries(shape).map(([key, schema]) => [key, extenders[key]?.(schema as any) ?? schema] as const),
  ) as Shape
}

export const extendSchema = <Shape extends Record<string, AnySchema>, Extenders extends ExtendersShape<Shape>>(
  shape: Shape, extenders: Extenders,
) => {
  return object(extendSchemaShape(shape, extenders))
}
